<template>
  <!-- 合同文件操作按钮组 -->
  <div class="butGroup">
    <!-- <icon-button
      content="预览"
      icon="iconfont iconchakan"
      @click="previewShow(scope.row)"
      v-if="scope.row.fileId !== '0'"
    /> -->
    <icon-button
      content="生成"
      icon="iconfont iconshengcheng"
      @click="getHtgCfgitem(scope.row)"
      v-if="showg(scope.row)"
    />

    <icon-button
      @click="appendFile(scope.row, 2, scope.$index)"
      content="追加文件"
      icon="iconfont iconzhuijia"
      v-if="scope.row.fileSource!=='3'"
    />
     <icon-button
      @click="deleteTemplate(scope.row, 4, scope.$index)"
      :content="'删除'+scope.row.documentName"
      icon="iconfont iconshanchu1"
      v-if="scope.row.required!=='1'"
    />
    <icon-button
      @click="viewResume(scope.row, 4, scope.$index)"
      content='文件履历'
      icon="iconfont iconlvli"
    />
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
// import BaseButton from '@/components/common/button/base-button/base-button.vue'
export default {
  components: { IconButton },
  props: {
    canSubmitFlag: {
      type: Boolean,
      default: false
    },
    scope: {

    }
  },
  data () {
    return {

    }
  },

  methods: {
    // 自定义签章类型文件没有生成按钮
    showg (row) {
      return !this.canSubmitFlag && row.isGenerate === '1' && row.documentId !== 'WSF_SIGN_FILE_001'
    },

    // 查看文件履历
    viewResume (row) {
      this.$emit('viewResume', row)
    },
    // 预览
    previewShow (row) {
      this.$emit('previewShow', row)
    },
    // 单个文件生成
    getHtgCfgitem (row) {
      if (row.fileListDetailVOS.length > 0) {
        this.$confirm('生成文件后会覆盖原来已有文件，是否继续生成?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$emit('getHtgCfgitem', row)
        }).catch(() => {

        })
      } else {
        this.$emit('getHtgCfgitem', row)
      }
    },
    // 文件上传
    Upload (param, row, type) {
      this.$emit('Upload', param, row, type)
    },
    // 文件删除
    deleteTemplate (row, type, index) {
      this.$emit('deleteTemplate', row, type, index)
    },
    // 追加文件
    appendFile (row, type, index) {
      this.$emit('appendFile', row, type, index)
    }
  }
}
</script>
<style lang="scss" scoped>
.butGroup{
  display: flex;
  justify-content: center;
}
</style>
